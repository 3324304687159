@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.call_emergency {
  width: auto;
  border-radius: 2rem;
  padding: 1rem 2rem;
  background: #ff1f27;
  display: flex;
  align-items: center;
  flex-flow: column;
  text-align: center;
}

a.call_emergency .icons {
  color: #fff;
  background: #d8d2d259;
  padding: 6px 17px;
  font-size: 50px;
  border-radius: 50%;
  margin-bottom: 20px;
}

.contact_page {
  padding: 80px 0px 100px 0px;
}

.contact_us p {
  font-family: poppins !important;
  font-size: 18px;
}

@media screen and (min-width: 0px) and (max-width: 530px) {
  .contact_us p {
    font-size: 15px;
    text-align: justify;
    line-height: 25px;
  }
  .contact_us h1 {
    font-size: 25px;
  }
  .contact_us h2 {
    font-size: 20px;
    margin-top: 25px;
  }

  .contact_page {
    padding: 50px 0px 100px 0px;
  }
  .contact h3 {
    font-size: 17px;
  }
  .contact p {
    font-size: 13px;
    text-align: center;
  }
}

.contact_us h2 {
  margin-bottom: 0px !important;
  color: #ff1f27 !important;
}

.contact_us h1 {
  margin-bottom: 20px !important;
}

.About_page {
  padding: 70px 10px;
}

.About_page p {
  padding-top: 15px;
  font-size: 15px;
  text-align: justify;
  color: gray;
}

.about_bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.733), rgba(0, 0, 0, 0.5)),
    /* url("https://images.pond5.com/ambulance-emergency-lights-flashing-night-footage-074063678_iconl.jpeg"); */
      url("./images/swiftcare\ ambulances.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 70vh;
}

.new_home {
  background-image: url("./images/BG New.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-top: 20px;
  margin-top: -180px; */
}
/* .new_home_mobile {
  background-image: url("./images/BG New Mobile.png");
  height: 130vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  margin-top: -180px;
} */

.new_home_mobile {
  background-image: url("./images/BG New Mobile.png");
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-top: 30px; */
  margin-top: -60px;
}

.home_for_mobile {
  display: none;
}
@media screen and (min-width: 0px) and (max-width: 767px) {
  .home_for_large {
    display: none;
  }
  .home_for_mobile {
    display: block !important;
    padding-top: 20px;
  }

  .col_md_6_text h2 {
    margin-bottom: 30px;
  }
  .col_md_6_text p {
    margin-top: 40px;
    text-align: center;
  }
  .new_home {
    height: auto;
    padding-bottom: 80px;
  }
  .col_md_6_text h2 {
    color: #ff1f27;
    font-size: 30px !important;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 5px;
  }
  .home_banner_form h5 {
    font-size: 13px !important;
  }
  .home_banner_form {
    padding: 20px !important;
  }
  .col_md_6_text p {
    margin-top: 20px;
  }
  .new_home {
    padding-top: 120px;
    margin-top: -22px;
  }

  .col_md_6_text p {
    margin-bottom: 10px;
    font-size: 13px !important;
  }
}

.red_fixed_button_home {
  background-color: #ff1f27;
  color: #fff;
  font-size: 20px;
  font-weight: bold;
  padding: 7px 15px;
  border: 1px solid #ff1f27;
  border-radius: 5px;
}

.green_fixed_button_nav {
  background-color: #25d366;
  padding: 7px 10px;
  color: #fff !important;
}

.new_home_container {
  padding-top: 100px;
}

.blink {
  color: #ff1f27;
  -webkit-animation: blink 800ms step-end infinite;
  animation: blink 800ms step-end infinite;
}
@-webkit-keyframes blink {
  50% {
    color: black;
  }
}
@keyframes blink {
  50% {
    color: black;
  }
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #ff1f27 !important;
  background-clip: padding-box;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
  margin-top: 30px !important;
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: -30px !important;
  left: 0;
  z-index: 15;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  padding-left: 0;
  margin-right: 15%;
  margin-left: 15%;
  list-style: none;
  margin-top: 20px !important;
}

.Blogs_bg {
  background-image: linear-gradient(rgba(0, 0, 0, 0.733), rgba(0, 0, 0, 0.5)),
    /* url("https://images.pond5.com/ambulance-emergency-lights-flashing-night-footage-074063678_iconl.jpeg"); */
      url("https://img.freepik.com/free-photo/side-british-ambulance_53876-63444.jpg?t=st=1727110952~exp=1727114552~hmac=7191197c6e2458356d1bdfc795c319136ca2de20585be02d0849ad98f8ce4461&w=996");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  height: 70vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Blogs_bg h1 {
  color: #fff !important;
  font-size: 30px;
}

@media screen and (max-width: 991px) {
  .Blogs_bg h1 {
    font-size: 20px;
    text-align: center;
  }
  .blogs_container h2 {
    font-size: 20px;
  }
  .blogs_container .card {
    padding: 30px 17px;
  }
}
.blogs_container .card {
  padding: 40px 25px;
  border: none !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.blogs_container h4 {
  color: #ff1f27;
  font-weight: 800;
  font-size: 19px;
  margin-bottom: 20px;
}
.blogs_container p {
  color: gray;
}
.truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.truncate1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blogs_container button {
  background-color: #ff1f27;
  width: fit-content;
  border: none;
  border-radius: 5px;
  padding: 7px 20px;
  color: #fff;
}
.blogs_container i {
  color: #ff1f27;
  margin-left: 10px;
  padding-right: 5px;
}

.Blogs_bg1 {
  background-image: linear-gradient(rgba(0, 0, 0, 0.733), rgba(0, 0, 0, 0.5)),
    /* url("https://images.pond5.com/ambulance-emergency-lights-flashing-night-footage-074063678_iconl.jpeg"); */
      url("https://img.freepik.com/free-photo/side-british-ambulance_53876-63444.jpg?t=st=1727110952~exp=1727114552~hmac=7191197c6e2458356d1bdfc795c319136ca2de20585be02d0849ad98f8ce4461&w=996");
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  height: 60vh !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Blogs_bg1 h1 {
  color: #fff !important;
  font-size: 30px;
}

@media screen and (max-width: 991px) {
  .Blogs_bg1 h1 {
    font-size: 20px;
    text-align: center;
    padding: 0px 10px;
  }
  .blogs_container1 p{
    text-align: justify;
  }
  .blogs_container1 h3 {
    margin-top: 50px;
    color: #ff1f27;
    font-size: 26px;
    margin-bottom: 30px;
} 
}

.blogs_container1 h3 {
  margin-top: 50px;
  color: #ff1f27;
}
.blogs_container1 a{
  color: #ff1f27 !important;
}
.blogs_container1 p{
  color: gray !important;
  font-size: 15px;
}
.blogs_container1 h6 {
  margin-top: 20px;
  color: #565656;
}
.blogs_container1 h5 {
  margin-top: 30px;
  color: #000;
}
.blogs_container1 i {
  color: #ff1f27;
  margin-left: 10px;
  padding-right: 5px;
}